// Labels (not the form kind)

// this :not() prevents a style clash with Mermaid.js's
// diagram labels, which also use .label
// for more, see https://github.com/just-the-docs/just-the-docs/issues/1272
// and the accompanying PR
.label:not(g),
.label-blue:not(g) {
  display: inline-block;
  padding: 0.16em 0.56em;
  margin-right: $sp-2;
  margin-left: $sp-2;
  color: $white;
  text-transform: uppercase;
  vertical-align: middle;
  background-color: $blue-100;
  border-radius: 12px;

  @include fs-2;
}

.label-green:not(g) {
  background-color: $green-200;
}

.label-purple:not(g) {
  background-color: $purple-100;
}

.label-red:not(g) {
  background-color: $red-200;
}

.label-yellow:not(g) {
  color: $grey-dk-200;
  background-color: $yellow-200;
}
