// Utility classes for colors

// Text colors

.text-grey-dk-000 {
  color: $grey-dk-000 !important;
}

.text-grey-dk-100 {
  color: $grey-dk-100 !important;
}

.text-grey-dk-200 {
  color: $grey-dk-200 !important;
}

.text-grey-dk-250 {
  color: $grey-dk-250 !important;
}

.text-grey-dk-300 {
  color: $grey-dk-300 !important;
}

.text-grey-lt-000 {
  color: $grey-lt-000 !important;
}

.text-grey-lt-100 {
  color: $grey-lt-100 !important;
}

.text-grey-lt-200 {
  color: $grey-lt-200 !important;
}

.text-grey-lt-300 {
  color: $grey-lt-300 !important;
}

.text-blue-000 {
  color: $blue-000 !important;
}

.text-blue-100 {
  color: $blue-100 !important;
}

.text-blue-200 {
  color: $blue-200 !important;
}

.text-blue-300 {
  color: $blue-300 !important;
}

.text-green-000 {
  color: $green-000 !important;
}

.text-green-100 {
  color: $green-100 !important;
}

.text-green-200 {
  color: $green-200 !important;
}

.text-green-300 {
  color: $green-300 !important;
}

.text-purple-000 {
  color: $purple-000 !important;
}

.text-purple-100 {
  color: $purple-100 !important;
}

.text-purple-200 {
  color: $purple-200 !important;
}

.text-purple-300 {
  color: $purple-300 !important;
}

.text-yellow-000 {
  color: $yellow-000 !important;
}

.text-yellow-100 {
  color: $yellow-100 !important;
}

.text-yellow-200 {
  color: $yellow-200 !important;
}

.text-yellow-300 {
  color: $yellow-300 !important;
}

.text-red-000 {
  color: $red-000 !important;
}

.text-red-100 {
  color: $red-100 !important;
}

.text-red-200 {
  color: $red-200 !important;
}

.text-red-300 {
  color: $red-300 !important;
}

// Background colors

.bg-grey-dk-000 {
  background-color: $grey-dk-000 !important;
}

.bg-grey-dk-100 {
  background-color: $grey-dk-100 !important;
}

.bg-grey-dk-200 {
  background-color: $grey-dk-200 !important;
}

.bg-grey-dk-250 {
  background-color: $grey-dk-250 !important;
}

.bg-grey-dk-300 {
  background-color: $grey-dk-300 !important;
}

.bg-grey-lt-000 {
  background-color: $grey-lt-000 !important;
}

.bg-grey-lt-100 {
  background-color: $grey-lt-100 !important;
}

.bg-grey-lt-200 {
  background-color: $grey-lt-200 !important;
}

.bg-grey-lt-300 {
  background-color: $grey-lt-300 !important;
}

.bg-blue-000 {
  background-color: $blue-000 !important;
}

.bg-blue-100 {
  background-color: $blue-100 !important;
}

.bg-blue-200 {
  background-color: $blue-200 !important;
}

.bg-blue-300 {
  background-color: $blue-300 !important;
}

.bg-green-000 {
  background-color: $green-000 !important;
}

.bg-green-100 {
  background-color: $green-100 !important;
}

.bg-green-200 {
  background-color: $green-200 !important;
}

.bg-green-300 {
  background-color: $green-300 !important;
}

.bg-purple-000 {
  background-color: $purple-000 !important;
}

.bg-purple-100 {
  background-color: $purple-100 !important;
}

.bg-purple-200 {
  background-color: $purple-200 !important;
}

.bg-purple-300 {
  background-color: $purple-300 !important;
}

.bg-yellow-000 {
  background-color: $yellow-000 !important;
}

.bg-yellow-100 {
  background-color: $yellow-100 !important;
}

.bg-yellow-200 {
  background-color: $yellow-200 !important;
}

.bg-yellow-300 {
  background-color: $yellow-300 !important;
}

.bg-red-000 {
  background-color: $red-000 !important;
}

.bg-red-100 {
  background-color: $red-100 !important;
}

.bg-red-200 {
  background-color: $red-200 !important;
}

.bg-red-300 {
  background-color: $red-300 !important;
}
