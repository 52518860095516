// Colored button

@mixin btn-color($fg, $bg) {
  color: $fg;
  background-color: darken($bg, 2%);
  background-image: linear-gradient(lighten($bg, 5%), darken($bg, 2%));
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.25),
    0 4px 10px rgba(0, 0, 0, 0.12);

  &:hover,
  &.zeroclipboard-is-hover {
    color: $fg;
    background-color: darken($bg, 4%);
    background-image: linear-gradient((lighten($bg, 2%), darken($bg, 4%)));
  }

  &:active,
  &.selected,
  &.zeroclipboard-is-active {
    background-color: darken($bg, 5%);
    background-image: none;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
  }

  &.selected:hover {
    background-color: darken($bg, 10%);
  }
}
