// Code and syntax highlighting
// stylelint-disable selector-no-qualifying-type, declaration-block-semicolon-newline-after,declaration-block-single-line-max-declarations, selector-no-type, selector-max-type, scss/comment-no-empty

// {% raw %}

// This instruction applies to all queues not within 'pre' or 'figure', avoiding 'code' generated by the highlight.
:not(pre, figure) {
  & > code {
    padding: 0.2em 0.15em;
    font-weight: 400;
    background-color: $code-background-color;
    border: $border $border-color;
    border-radius: $border-radius;
  }
}

// Avoid appearance of dark border around visited code links in Safari
a:visited code {
  border-color: $border-color;
}

// Content structure for highlighted code blocks using fences or Liquid
//
// ```[LANG]...```, no kramdown line_numbers:
//   div.[language-LANG.]highlighter-rouge > div.highlight > pre.highlight > code
//
// ```[LANG]...```, kramdown line_numbers = true:
//   div.[language-LANG.]highlighter-rouge > div.highlight > pre.highlight > code
//   > div.table-wrapper > table.rouge-table > tbody > tr
//   > td.rouge-gutter.gl > pre.lineno
//   | td.rouge-code > pre
//
// {% highlight LANG %}...{% endhighlight %}:
//   figure.highlight > pre > code.language-LANG
//
// {% highlight LANG linenos %}...{% endhighlight %}:
//   figure.highlight > pre > code.language-LANG
//   > div.table-wrapper > table.rouge-table > tbody > tr
//   > td.gutter.gl > pre.lineno
//   | td.code > pre
//
// ----...---- (AsciiDoc)
//   div.listingblock > div.content > pre.rouge.highlight
//
// fix_linenos removes the outermost pre when it encloses table.rouge-table
//
// See docs/index-test.md for some tests.
//
// No kramdown line_numbers: fences and Liquid highlighting look the same.
// Kramdown line_numbers = true: fences have a wider gutter than with Liquid?

// ```[LANG]...```
// or in AsciiDoc:
//
//     ----
//     ...
//     ----

// the code may appear with 3 different types:
// container \ case:  default case,          code with line number,   code with html rendering
// top level:         div.highlighter-rouge, figure.highlight,        figure.highlight
// second level:      div.highlight,         div.table-wrapper,       pre.highlight
// third level:       pre.highlight,         td.code,                 absent
// last level:        code,                  pre,                     code (optionality)
// highlighter level: span,                  span,                    span
// the spacing are only in the second level for case 1, 3 and in the third level for case 2
// in AsciiDoc, there is a parent container that contains optionally a title and the content.

// select top level container
div.highlighter-rouge,
div.listingblock > div.content,
figure.highlight {
  margin-top: 0;
  margin-bottom: $sp-3;
  background-color: $code-background-color;
  border-radius: $border-radius;
  box-shadow: none;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 0;

  // copy button (or other button)
  // the button appear only when there is a hover on the code or focus on button
  > button {
    width: $sp-3;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    border: $sp-3 solid $code-background-color;
    background-color: $code-background-color;
    color: $body-text-color;
    box-sizing: content-box;

    svg {
      fill: $body-text-color;
    }

    &:active {
      text-decoration: none;
      outline: none;
      opacity: 1;
    }

    &:focus {
      opacity: 1;
    }
  }

  // the button can be seen by doing a simple hover in the code, there is no need to go over the location of the button
  &:hover {
    > button {
      cursor: copy;
      opacity: 1;
    }
  }
}

// setting the spacing and scrollbar on the second level for the first case
// remove all space on the second and third level
// this is a mixin to accommodate for the slightly different structures generated via Markdown vs AsciiDoc
@mixin scroll-and-spacing($code-div, $pre-select) {
  #{$code-div} {
    overflow-x: auto;
    padding: $sp-3;
    margin: 0;
    border: 0;
  }

  #{$pre-select},
  code {
    padding: 0;
    margin: 0;
    border: 0;
  }
}

// for Markdown
div.highlighter-rouge {
  @include scroll-and-spacing("div.highlight", "pre.highlight");
}

// for AsciiDoc. we also need to fix the margins for its parent container.
div.listingblock {
  margin-top: 0;
  margin-bottom: $sp-3;

  @include scroll-and-spacing("div.content", "div.content > pre");
}

// {% highlight LANG %}...{% endhighlight %},
// {% highlight LANG linenos %}...{% endhighlight %}:

// setting the spacing and scrollbar on the second level for the thirt case
// the css rule are apply only to the last code enviroment
// setting the scroolbar
figure.highlight {
  pre,
  :not(pre) > code {
    overflow-x: auto;
    padding: $sp-3;
    margin: 0;
    border: 0;
  }
}

// ```[LANG]...```, kramdown line_numbers = true,
// {% highlight LANG linenos %}...{% endhighlight %}:

// setting the spacing and scrollbar on the thirt level for the second case
.highlight .table-wrapper {
  padding: $sp-3 0;
  margin: 0;
  border: 0;
  box-shadow: none;

  td,
  pre {
    min-width: 0;
    padding: 0;
    background-color: $code-background-color;
    border: 0;

    @include fs-2;
  }

  td.gl {
    width: 1em;
    padding-right: $sp-3;
    padding-left: $sp-3;
  }

  pre {
    margin: 0;
    line-height: 2;
  }
}

// Code examples: html render of a code
.code-example,
.listingblock > .title {
  padding: $sp-3;
  margin-bottom: $sp-3;
  overflow: auto;
  border: 1px solid $border-color;
  border-radius: $border-radius;

  + .highlighter-rouge,
  + .sectionbody .listingblock,
  + .content,
  + figure.highlight {
    position: relative;
    margin-top: -$sp-4;
    border-right: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    border-left: 1px solid $border-color;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

// Mermaid diagram code blocks should be left unstyled.
code.language-mermaid {
  padding: 0;
  background-color: inherit;
  border: 0;
}

// Override OneDarkJekyll Colors for Code Blocks
.highlight,
pre.highlight {
  background: $code-background-color; // Code Background
  // For Backwards Compatibility Before $code-linenumber-color was added
  @if variable-exists(code-linenumber-color) {
    color: $code-linenumber-color; // Code Line Numbers
  } @else {
    color: $body-text-color; // Code Line Numbers
  }
}

// Override OneDarkJekyll Colors for Code Blocks
.highlight pre {
  background: $code-background-color; // Code Background
}

// {% endraw %}
