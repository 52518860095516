// Utility classes for lists

// stylelint-disable selector-max-type

.list-style-none {
  padding: 0 !important;
  margin: 0 !important;
  list-style: none !important;

  li {
    &::before {
      display: none !important;
    }
  }
}
